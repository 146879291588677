<template>
  <div v-loading="loading" class="details">
    <div class="title">
      {{ $route.query.name
      }}<span v-if="$route.query.subjectId">-{{ subjectName }}</span
      >-{{ $route.query.topSectionName }}名单
    </div>
    <template v-if="dataList.length">
      <div v-for="(item, index) in dataList" :key="index" class="table">
        <div :class="['table-title', item.isViewSchool == 1 ? 'active' : '']">
          {{ item.schoolName }}，{{ item.total }}人
        </div>
        <div class="table-box">
          <a-table
            bordered
            :row-key="(record) => record.id"
            :columns="columns"
            :data-source="item.students"
            :pagination="false"
          ></a-table>
        </div>
      </div>
    </template>
    <no-data v-else />
  </div>
</template>

<script>
import { getstatsubjectoption } from "@/core/api/academic/common";

import {
  subjecttopsectioncomparesingletopsection,
  totaltopsectioncomparesingletopsection,
} from "@/core/api/academic/unionSheet";
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      subjectName: "",
      loading: false,
      dataList: [],
      columns: [
        {
          title: "姓名",
          align: "center",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "考号",
          align: "center",
          dataIndex: "studentExamId",
          width: 100,
        },
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
          width: 100,
        },
        {
          title: "班级",
          align: "center",
          dataIndex: "classNum",
          width: 100,
        },
        {
          title: "整体排名",
          align: "center",
          dataIndex: "unionRank",
          width: 100,
        },
        {
          title: "成绩",
          align: "center",
          dataIndex: "score",
          width: 100,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  created() {
    this.getSubject();
    if (this.$route.query.subjectId) {
      this.getSubjectData();
    } else {
      this.getTotalData();
    }
  },
  methods: {
    async getSubject() {
      const res = await getstatsubjectoption({ id: this.$route.query.id });
      let subject = res.data.data.find(
        (item) => item.id == this.$route.query.subjectId
      );
      this.subjectName = subject.name;
    },

    async getTotalData() {
      try {
        this.loading = true;
        const res = await totaltopsectioncomparesingletopsection({
          id: this.$route.query.id,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          thisSchoolId: this.$route.query.thisSchoolId,
          topSectionId: this.$route.query.topSectionId,
          eleccateSubjectId: 0,
        });
        this.dataList = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getSubjectData() {
      try {
        this.loading = true;
        const res = await subjecttopsectioncomparesingletopsection({
          id: this.$route.query.id,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          subjectId: this.$route.query.subjectId,
          thisSchoolId: this.$route.query.thisSchoolId,
          topSectionId: this.$route.query.topSectionId,
          eleccateSubjectId: 0,
        });
        this.dataList = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  box-sizing: border-box;
  padding: 24px;
  .title {
    color: #0a1119;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
}
.table {
  margin-bottom: 24px;
  .table-title {
    text-align: center;
    margin-bottom: 8px;
    color: #0a1119;
    font-size: 16px;
  }

  .active {
    color: #f56c6b;
  }
}
</style>
